import React from "react"
import Seo from "../../../components/seo"
import Layout from "../../../app/layout"
import { Link, graphql } from "gatsby"

import { Row, Col, Breadcrumb, Button } from "antd"
import { ChevronRight } from "../../../components/Icon"

import Container from "../../../components/Container"
import Masthead from "../../../components/Masthead"
import { useI18next } from "gatsby-plugin-react-i18next"

const AwardeePanduan = ({
  data: {
    awardeeGranteeGuides: data = {
      page_setting: {},
      other_guides: [],
    },
  },
}) => {
  const { t } = useI18next()

  return (
    <Layout>
      <Seo title={data.page_setting.header_title} />

      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Breadcrumb.Item>
            <Link to="/">{t("Beranda")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{t("Penerima Beasiswa")}</Breadcrumb.Item>
          <Breadcrumb.Item>{data.page_setting.header_title}</Breadcrumb.Item>
        </Breadcrumb>
      </Container>

      <Masthead
        image={
          data.page_setting.header_video_thumbnail ||
          data.page_setting.header_image
        }
        title={data.page_setting.header_title}
        videoId={data.page_setting.header_video_url}
        useVideo={!!data.page_setting.header_use_video}
        textBottom={t("Selengkapnya")}
      />

      <div className="section section-secondary padding-bottom-none text-medium">
        <div className="overlap-section-left align-middle">
          <div className="overlap-section-col overlap-section-col-img">
            <img
              src={data.page_setting.header_sub_image}
              className="overlap-bottom"
              alt=""
            />
          </div>
          <div className="overlap-section-col overlap-section-col-content">
            <div
              dangerouslySetInnerHTML={{
                __html: data.page_setting.header_sub_description,
              }}
            />
          </div>
        </div>
      </div>

      <div
        className="section text-medium padding-bottom-none"
        style={{ marginTop: 40 }}
      >
        <Container>
          <div className="text-center">
            <h2 className="section-title">{t("Tujuan")}</h2>
          </div>
          <Row gutter={80} style={{ margin: "40px -15px 20px -15px" }}>
            <Col
              span={24}
              md={12}
              style={{ marginBottom: 15, padding: "0 15px" }}
            >
              <img
                src={data.page_setting.purpose_icon_1}
                alt={data.page_setting.purpose_title_1}
              />
              <h5 style={{ marginTop: 12 }}>
                {data.page_setting.purpose_title_1}
              </h5>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.page_setting.purpose_description_1,
                }}
              />
            </Col>
            <Col
              span={24}
              md={12}
              style={{ marginBottom: 15, padding: "0 15px" }}
            >
              <img
                src={data.page_setting.purpose_icon_2}
                alt={data.page_setting.purpose_title_2}
              />
              <h5 style={{ marginTop: 12 }}>
                {data.page_setting.purpose_title_2}
              </h5>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.page_setting.purpose_description_2,
                }}
              />
            </Col>
          </Row>

          <div className="widget bg-cream bg-orn-1">
            <div className="widget-inside">
              <Row justify="space-between" align="middle">
                <Col span={24} md={12}>
                  <h3>{data.page_setting.general_guide_title}</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.page_setting.general_guide_description,
                    }}
                  />
                </Col>
                <Col span={24} flex="none">
                  <Button size="large">
                    <Link
                      to={data.page_setting.general_guide_document}
                      target="_blank"
                    >
                      {t("Lihat Panduan")}
                    </Link>
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>

      <div className="section text-medium">
        <Container>
          <div className="text-center">
            <h2 className="section-title">{t("Panduan Lainnya")}</h2>
          </div>

          <Row gutter={30}>
            {data.other_guides.map((d, i) => (
              <Col span={24} md={8} key={i}>
                <div
                  className="widget bg-orange bg-orn-2"
                  style={{ backgroundImage: `url(${d.background_image})` }}
                >
                  <div className="widget-inside">
                    <h4>{d.title}</h4>

                    <Button
                      size="large"
                      className="btn-outline-white"
                      target="_blank"
                      href={d.link_url || d.document}
                    >
                      {t("Lebih Lanjut")}
                    </Button>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query awardeeGranteeGuidesQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    awardeeGranteeGuides(lang: { eq: $language }) {
      page_setting {
        general_guide_description
        general_guide_document
        general_guide_title
        header_image
        header_sub_description
        header_sub_image
        header_title
        header_use_video
        header_video_thumbnail
        header_video_url
        purpose_description_1
        purpose_description_2
        purpose_icon_1
        purpose_icon_2
        purpose_title_1
        purpose_title_2
        slug
      }
      other_guides {
        background_image
        document
        link_url
        title
      }
    }
  }
`
export default AwardeePanduan
